import { Component, ViewChild } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { Pagination } from 'app/shared/pagination';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';
import { ModalEditCustomerComponent } from '../modal-edit-customer/modal-edit-customer.component';

@Component({
	selector: 'modal-select-customer',
	templateUrl: './modal-select-customer.component.html'
})
export class ModalSelectCustomerComponent extends UIModalBaseComponent {

	@ViewChild(ModalEditCustomerComponent) customerEditModal: ModalEditCustomerComponent;

	customers;
	search = '';
	pagination = new Pagination();
	disabled = false;
	prefill = null;

	constructor(
		public app: AppService,
		private api: ApiService,
	) {
		super();
	}

	open(mdata: any) {
		this.search = mdata?.search || '';
		this.prefill = mdata?.prefill;
		this.customers = null;

		this.api.customer.list({
			archived: false
		}, customers => {
			this.customers = customers;
			this.modal.open();
		}, error => {
			this.app.notifications.showDanger(error.message);
		});
	}

	selectItem(item) {
		this.modal.close(item);
	}

	addCustomer() {
		this.customerEditModal.open({
			id: 'new',
			prefill: this.prefill
		});
	}

	customerEditModalClosed(id) {
		if (id) {
			this.disabled = true;
			this.api.customer.details(id, data => {
				this.disabled = false;
				this.modal.close(data.record);
			}, error => {
				this.disabled = false;
				this.app.notifications.showDanger(error.message);
			});
		}
	}

}
