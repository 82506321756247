import { Component, ViewChild } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { DecimalPipe } from 'app/shared/decimal.pipe';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';
import { ModalSelectAccountsAssignmentComponent } from '../modal-select-accounts-assignment/modal-select-accounts-assignment.component';

declare const Mangler: any;

@Component({
	selector: 'modal-edit-labour',
	templateUrl: './modal-edit-labour.component.html'
})
export class ModalEditLabourComponent extends UIModalBaseComponent {

	@ViewChild(ModalSelectAccountsAssignmentComponent) selectAssignmentModal: ModalSelectAccountsAssignmentComponent;

	id;
	details;
	categories;
	currencies;
	canDelete;
	canArchive;
	canUnarchive;
	disabled = false;

	multiCurrency = false;
	selectedPrice = null;

	get zeroHours() {
		const dayH = DecimalPipe.parse(this.details.hours_per_day);
		return dayH < 0.01;
	}

	constructor(
		public app: AppService,
		private api: ApiService,
	) {
		super();
	}

	open(mdata: any) {
		const categoryId = parseInt(mdata.categoryId, 10) || 0;
		this.id = mdata.id || 'new';
		this.details = null;
		this.categories = null;
		this.currencies = null;
		this.canDelete = false;
		this.canArchive = false;
		this.canUnarchive = false;
		this.selectedPrice = null;
		this.disabled = false;

		const success = data => {
			this.details = data.details || {};
			this.categories = data.categories;
			this.currencies = data.currencies;
			this.canDelete = data.can_delete;
			this.canArchive = data.can_archive;
			this.canUnarchive = data.can_unarchive;
			this.selectedPrice = this.details.prices[0];

			if (this.details.id === 'new' && categoryId) this.details.category_id = categoryId;
			if (!this.details.category_id) this.details.category_id = this.categories[0] ? this.categories[0].id : null;

			this.details.prices.forEach(p => {
				p.markup = null;
				p.margin = null;
				p.profit = null;
				p.dayCost = null;
				p.dayPrice = null;
				p.dayProfit = null;
			});

			// See if we need to enable multi-currency features for this form
			this.multiCurrency = this.currencies.length > 1 || this.details.prices[0].currency_id !== this.currencies[0].currency_id;

			this.recalculate();
			this.modal.open();
		};

		const fail = error => {
			this.app.notifications.showDanger(error.message);
		};

		if (this.id === 'new') {
			this.api.product.newLabourType(success, fail);
		} else {
			this.api.product.getLabourType(this.id, success, fail);
		}
	}

	recalculate(from = '', addThousandSeparators: boolean = true) {
		const dayH = DecimalPipe.parse(this.details.hours_per_day);

		this.details.prices.forEach(price => {
			let c = DecimalPipe.parse(price.hourly_cost);
			let mu = DecimalPipe.parse(price.markup);
			let mg = DecimalPipe.parse(price.margin);
			let p = DecimalPipe.parse(price.hourly_price);
			let pr = DecimalPipe.parse(price.profit);
			let dayC = DecimalPipe.parse(price.dayCost);
			let dayP = DecimalPipe.parse(price.dayPrice);
			let dayPr = DecimalPipe.parse(price.dayProfit);

			// Only do recalculation on the currently selected currency
			if (price === this.selectedPrice) {
				// If day values are supplied, calculate hourly values
				switch (from) {
					case 'dayCost': c = dayH < 0.01 ? 0 : dayC / dayH; break;
					case 'dayPrice': p = dayH < 0.01 ? 0 : dayP / dayH; break;
					case 'dayProfit': pr = dayH < 0.01 ? 0 : dayPr / dayH; break;
				}

				// Calculations

				switch (from) {
					case 'markup':
						pr = c * (mu / 100);
						p = c + pr;
						mg = p === 0 ? 0 : pr / p * 100;
						break;

					case 'margin':
						p = mg === 100 ? c : c / (1 - (mg / 100));
						pr = p - c;
						mu = c === 0 ? 0 : pr / c * 100;
						break;

					case 'dayProfit':
					case 'profit':
						p = c + pr;
						mu = c === 0 ? 0 : pr / c * 100;
						mg = p === 0 ? 0 : pr / p * 100;
						break;

					case 'dayCost':
					case 'cost':
						pr = c * (mu / 100);
						p = c + pr;
						break;

					case 'dayPrice':
					case 'price':
					default:
						pr = p - c;
						mu = c === 0 ? 0 : pr / c * 100;
						mg = p === 0 ? 0 : pr / p * 100;
						break;
				}
			}

			// Calculate daily values from hourlies

			dayC = c * dayH;
			dayP = p * dayH;
			dayPr = pr * dayH;

			// Apply calculated values back to fields

			if (price !== this.selectedPrice || from !== 'cost') price.hourly_cost = DecimalPipe.transform(c, 2, 4, addThousandSeparators);
			if (price !== this.selectedPrice || from !== 'markup') price.markup = DecimalPipe.transform(mu, 2, 4, addThousandSeparators);
			if (price !== this.selectedPrice || from !== 'margin') price.margin = DecimalPipe.transform(mg, 2, 4, addThousandSeparators);
			if (price !== this.selectedPrice || from !== 'price') price.hourly_price = DecimalPipe.transform(p, 2, 4, addThousandSeparators);
			if (price !== this.selectedPrice || from !== 'profit') price.profit = DecimalPipe.transform(pr, 2, 4, addThousandSeparators);

			if (price !== this.selectedPrice || from !== 'dayCost') price.dayCost = DecimalPipe.transform(dayC, 2, 4, addThousandSeparators);
			if (price !== this.selectedPrice || from !== 'dayPrice') price.dayPrice = DecimalPipe.transform(dayP, 2, 4, addThousandSeparators);
			if (price !== this.selectedPrice || from !== 'dayProfit') price.dayProfit = DecimalPipe.transform(dayPr, 2, 4, addThousandSeparators);
		});

		if (from !== 'hours_per_day') this.details.hours_per_day = DecimalPipe.transform(dayH, 2, 4, addThousandSeparators);
		if (from !== 'sales_multiplier') this.details.sales_multiplier = DecimalPipe.transform(this.details.sales_multiplier, 0, 4, addThousandSeparators);

		this.tutorialCheck();
	}

	save() {
		this.recalculate('', false);
		const labourDetails = Mangler.clone(this.details);
		this.recalculate();
		this.disabled = true;
		this.api.product.saveLabourType(labourDetails, savedId => {
			this.disabled = false;
			if (this.details.id === 'new') {
				this.app.notifications.showSuccess('Labour type created.');
				this.app.tutorial?.validateStep(305);
			} else {
				this.app.notifications.showSuccess('Labour type updated.');
			}
			this.modal.close({ id: savedId, categoryId: this.details.category_id });
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

	deleteLabour() {
		if (!this.canDelete) return;

		this.app.dialog.pop('Delete Labour Type', 'Are you sure you want to delete this labour type?', ['Cancel', '*Delete'], btn => {
			if (btn === 1) {
				this.disabled = true;
				this.api.product.deleteLabourType(this.id, () => {
					this.disabled = false;
					this.app.notifications.showSuccess('Labour type deleted.');
					this.modal.close('deleted');
				}, error => {
					this.disabled = false;
					this.app.notifications.showDanger(error.message);
				});
			}
		});
	}

	archiveLabour() {
		if (!this.canArchive) return;

		this.app.dialog.pop('Delete Labour Type', 'Are you sure you want to delete this labour type?', ['Cancel', '*Delete'], btn => {
			if (btn === 1) {
				this.disabled = true;
				this.api.product.archiveLabourType(this.id, () => {
					this.disabled = false;
					this.app.notifications.showSuccess('Labour type deleted.');
					this.modal.close('deleted');
				}, error => {
					this.disabled = false;
					this.app.notifications.showDanger(error.message);
				});
			}
		});
	}

	unarchiveLabour() {
		if (!this.canUnarchive) return;

		this.disabled = true;
		this.api.product.unarchiveLabourType(this.id, () => {
			this.disabled = false;
			this.app.notifications.showSuccess('Labour type restored.');
			this.modal.close(this.id);
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

	tutorialCheck() {
		if (!this.app.tutorial?.tutorial) return;

		const c = DecimalPipe.parse(this.details.prices[0].hourly_cost);
		const p = DecimalPipe.parse(this.details.prices[0].hourly_price);

		const descriptionOk = !!this.details?.description;
		const priceOk = c > 0 && p > 0;

		if (!descriptionOk) {
			this.app.tutorial?.invalidateStep(307);
			this.app.tutorial?.invalidateStep(306);
		} else if (priceOk) {
			this.app.tutorial?.validateStep(306);
			this.app.tutorial?.validateStep(307);
		} else {
			this.app.tutorial?.validateStep(306);
			this.app.tutorial?.invalidateStep(307);
		}
	}

	currenciesNotAdded() {
		const list = this.currencies.map(item => item.currency_id);
		this.details.prices.forEach(pp => {
			const i = list.indexOf(pp.currency_id);
			if (i !== -1) list.splice(i, 1);
		});
		return list;
	}

	isPrimaryPrice() {
		return this.details.prices.indexOf(this.selectedPrice) === 0;
	}

	setPrimaryPrice() {
		if (!this.selectedPrice) return;

		const i = this.details.prices.indexOf(this.selectedPrice);
		if (i !== -1) {
			this.details.prices.splice(i, 1);
			this.details.prices.unshift(this.selectedPrice);
		}
	}

	removePrice() {
		if (this.isPrimaryPrice() || !this.selectedPrice) return;

		const currency = this.selectedPrice.currency_id;

		this.app.dialog.pop('Delete ' + currency + ' price', 'Are you sure you want to remove the ' + currency + ' price from this labour type?', ['Cancel', '-Delete Price'], button => {
			if (button === 1) {
				const i = this.details.prices.indexOf(this.selectedPrice);
				if (i !== -1) {
					this.details.prices.splice(i, 1);
				}

				this.selectedPrice = this.details.prices[0];
			}
		});
	}

	addCurrency(currency) {
		const newPrice = {
			currency_id: currency,
			hourly_cost: 0,
			hourly_price: 0,
			markup: null,
			margin: null,
			profit: null,
			dayCost: null,
			dayPrice: null,
			dayProfit: null
		};

		this.details.prices.push(newPrice);
		this.selectedPrice = newPrice;

		this.recalculate();
	}

	selectAssignment() {
		this.selectAssignmentModal.open();
	}

	selectAssignmentModalClosed(data) {
		if (data) this.details.accounts_assignment = data;
	}

}
