<form>
	<ui-modal modalTitle="Mark Proposal As Sent">
		<ng-container *ngIf="issues">
			<div *ngIf="issues.warnings.unmatched_address" class="alert" [ngClass]="{ 'alert-danger': !confirmed['unmatched_address'], 'alert-success': confirmed['unmatched_address'] }">
				<div class="d-flex align-items-center">
					<div class="flex-weight-1">
						<div class="text-bold mb-3">Address doesn't match any customer address on file.</div>
						<div *ngIf="issues.warnings.unmatched_address.address_line_1">{{issues.warnings.unmatched_address.address_line_1}}</div>
						<div *ngIf="issues.warnings.unmatched_address.address_line_2">{{issues.warnings.unmatched_address.address_line_2}}</div>
						<div *ngIf="issues.warnings.unmatched_address.address_line_3">{{issues.warnings.unmatched_address.address_line_3}}</div>
						<div *ngIf="issues.warnings.unmatched_address.posttown">{{issues.warnings.unmatched_address.posttown}}</div>
						<div *ngIf="issues.warnings.unmatched_address.county">{{issues.warnings.unmatched_address.county}}</div>
						<div *ngIf="issues.warnings.unmatched_address.postcode">{{issues.warnings.unmatched_address.postcode}}</div>
						<div *ngIf="issues.warnings.unmatched_address.country">{{issues.warnings.unmatched_address.country}}</div>
					</div>
					<div class="ml-3">
						<button *ngIf="!confirmed['unmatched_address']" type="button" class="btn btn-sm btn-danger" (click)="confirmed['unmatched_address'] = !confirmed['unmatched_address']">Confirm</button>
						<button *ngIf="confirmed['unmatched_address']" type="button" class="btn btn-sm btn-success" (click)="confirmed['unmatched_address'] = !confirmed['unmatched_address']"><i class="wq wq-check mr-1"></i>Confirmed</button>
					</div>
				</div>
			</div>
			<div *ngFor="let issueType of issueList()" class="alert" [ngClass]="{ 'alert-danger': !confirmed[issueType], 'alert-success': confirmed[issueType] }">
				<div class="d-flex align-items-center">
					<div class="flex-weight-1">
						<b>{{issues.warnings[issueType].length}} {{issues.warnings[issueType].length === 1 ? 'item' : 'items'}}</b>
						<span *ngIf="issueType === 'zero_cost'">with zero cost.</span>
						<span *ngIf="issueType === 'zero_price'">with zero price.</span>
						<span *ngIf="issueType === 'zero_margin'">with zero margin.</span>
						<span *ngIf="issueType === 'negative_margin'">with negative margin.</span>
						<span *ngIf="issueType === 'negative_labour_hour_margin'">with labour mismatch.</span>
						<span *ngIf="issueType === 'special_order_items'">on special order. These items are non-returnable and non-refundable.</span>
					</div>
					<div class="ml-3">
						<button type="button" class="btn btn-sm btn-secondary" (click)="issueDetails(issueType)"><i class="wq wq-search mr-1"></i>Details</button>
					</div>
					<div class="ml-3">
						<button *ngIf="!confirmed[issueType]" type="button" class="btn btn-sm btn-danger" (click)="confirmed[issueType] = !confirmed[issueType]">Confirm</button>
						<button *ngIf="confirmed[issueType]" type="button" class="btn btn-sm btn-success" (click)="confirmed[issueType] = !confirmed[issueType]"><i class="wq wq-check mr-1"></i>Confirmed</button>
					</div>
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="app.routeData.module === 'quote'">
			<p>Confirm that you have previewed, double-checked your quote and are ready to send to the customer. This quote will be marked as sent and you will need to create a new revision to make any changes to its items or pricing.</p>

			<p><b>Once your proposal is marked as sent, you will be able to send it to the customer.</b></p>

			<ui-form labelWidth="10rem">
				<ui-form-control label="Sent date">
					<p-calendar [(ngModel)]="record.quote_date" [ngModelOptions]="{ standalone: true }" [dateFormat]="app.calendarDateFormat" inputStyleClass="form-control w-7r" appendTo="body"></p-calendar>
				</ui-form-control>
				<ui-form-control label="Expiry date">
					<p-calendar [(ngModel)]="record.expiry_date" [ngModelOptions]="{ standalone: true }" [dateFormat]="app.calendarDateFormat" inputStyleClass="form-control w-7r" appendTo="body"></p-calendar>
				</ui-form-control>
				<ui-form-control label="Previous proposals" *ngIf="record.has_revisions">
					<label class="custom-control custom-checkbox mt-2 d-inline-block">
						<input type="checkbox" class="custom-control-input" [ngModelOptions]="{ standalone: true }" [(ngModel)]="disableOldProposals">
						<span class="custom-control-label">Disable all previous proposals of this quote <i class="wq wq-info ml-1" ngbTooltip="If checked, all the previous proposals of the quote will be disabled for customers."></i></span>
					</label>
					<label *ngIf="disableOldProposals" class="custom-control custom-checkbox mt-2 d-inline-block">
						<input type="checkbox" class="custom-control-input" [ngModelOptions]="{ standalone: true }" [(ngModel)]="redirectOldProposals">
						<span class="custom-control-label">Redirect customers to latest version <i class="wq wq-info ml-1" ngbTooltip="If checked, previous proposal links will redirect to the latest version instead of just being disabled. They will be matched by template, then by name."></i></span>
					</label>
				</ui-form-control>
			</ui-form>
		</ng-container>

		<ng-container *ngIf="app.routeData.module === 'sales-order'">
			<p>Confirm that you have previewed, double-checked your sales order before marking it as complete.</p>
			<ui-form labelWidth="10rem">
				<ui-form-control label="Accepted date">
					<p-calendar [(ngModel)]="record.accepted_date" [ngModelOptions]="{ standalone: true }" [dateFormat]="app.calendarDateFormat" inputStyleClass="form-control w-7r" appendTo="body"></p-calendar>
				</ui-form-control>
			</ui-form>

			<ng-container *ngIf="quota && quota.cost">
				<hr>
				<p class="text-center text-secondary text-uppercase text-small text-bold">Quotes value <span [ngClass]="{ 'text-success': quota.new_spent <= quota.quota, 'text-danger': quota.new_spent > quota.quota }">{{quota.new_spent | decimal:0:0:true:'GBP'}}</span> / {{quota.quota | decimal:0:0:true:'GBP'}}</p>
				<p *ngIf="quota.new_spent > quota.quota" class="text-center text-secondary text-small">This will take you over your accepted quote value limit. You are able to accept this quote but must upgrade to accept further quotes.</p>
				<p *ngIf="quota.new_quota_date" class="text-center text-secondary text-bold text-small">New quota available {{quota.new_quota_date | mySQLDateToISO | localizedDate:'shortDate'}}</p>
			</ng-container>
		</ng-container>

		<button type="button" class="btn btn-secondary" (click)="modal.close()" ui-modal-footer>Cancel</button>
		<button *ngIf="app.routeData.module === 'quote'" type="submit" class="btn btn-purple" (click)="markAsSentButton()" [disabled]="!isConfirmed()" ui-modal-footer>Mark As Sent</button>
		<button *ngIf="app.routeData.module === 'sales-order'" type="submit" class="btn btn-success" (click)="markAsCompletedButton()" [disabled]="!isConfirmed()" ui-modal-footer>Mark As Completed</button>
	</ui-modal>
</form>

<ui-modal [modalTitle]="issueTitle" [scrollable]="true" size="xl" #viewLines>
	<table *ngIf="data" class="table table-hover table-condensed vam">
		<thead>
			<tr>
				<th class="sticky" colspan="3">Product</th>
				<th class="sticky">Subsystem</th>
				<th class="sticky">Area</th>
				<th class="text-right sticky">Labour</th>
				<ng-container *ngIf="type ==='negative_labour_hour_margin'">
					<th class="text-right sticky">Billable Hours</th>
					<th class="text-right sticky">Recorded Hours</th>
				</ng-container>
				<ng-container *ngIf="type !=='negative_labour_hour_margin'">
					<th class="text-right sticky">Cost Price</th>
					<th class="text-right sticky">Sell Price</th>
					<th class="text-center sticky">Quantity</th>
				</ng-container>
				<th class="text-center sticky">Item Type</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let item of data.lines">

				<td class="shrink pr-0">
					<div *ngIf="item.css_background" class="product-image mr-3" [style.backgroundImage]="item.css_background | safeStyle"></div>
				</td>
				<td colspan="2" class="pl-0">
					<div *ngIf="item.product_id !== null && item.short_description" class="text-bold">{{item.short_description}}</div>
					<div *ngIf="item.product_id === null && item.description" class="text-bold">{{item.description}}</div>
					<div *ngIf="item.product_id === null && item.subtitle" class="text-secondary">{{item.subtitle}}</div>
					<div *ngIf="item.manufacturer_name || item.model" class="text-secondary">{{item.manufacturer_name}} {{item.manufacturer_name && item.model ? '/' : ''}} {{item.model}}</div>
					<div *ngIf="item.sku || item.manufacturer_sku" class="text-secondary text-tiny">{{item.sku || item.manufacturer_sku}}</div>

					<div *ngIf="item.supplied_by_customer" class="text-info"><i class="wq wq-info"></i> Supplied by customer</div>
					<div *ngIf="item.special_order" class="text-warning"><i class="wq wq-warning"></i> Special order item</div>
					<div *ngIf="isDiscontinued(item.product_id)" class="text-danger text-bold"><i class="wq wq-error"></i> Product discontinued</div>
					<div *ngIf="item.notes" class="text-info"><i class="wq wq-info"></i> {{item.notes}}</div>
					<div *ngIf="item.accessories_count" class="text-success"><i class="wq wq-product"></i> {{item.accessories_count}} {{item.accessories_count === 1 ? 'accessory' : 'accessories'}}</div>

					<ng-container *ngIf="!item.supplied_by_customer">
						<div *ngIf="item.product_id && item.unit_cost === 0"><span class="badge badge-danger mt-1">Zero Cost</span></div>
						<div *ngIf="item.product_id && item.unit_price === 0"><span class="badge badge-danger mt-1">Zero Price</span></div>
						<div *ngIf="item.unit_cost === item.unit_price"><span class="badge badge-danger mt-1">Zero Margin</span></div>
						<div *ngIf="item.unit_cost > item.unit_price"><span class="badge badge-danger mt-1">Negative Margin</span></div>
						<div *ngIf="item.total_recorded_hours && item.unit_labour_hours < item.total_recorded_hours"><span class="badge badge-danger mt-1">Labour Hour Mismatch</span></div>
					</ng-container>
				</td>

				<td>{{item.category_description}}</td>

				<td>{{item.parent_id === null ? item.structure_description : ''}}</td>

				<td class="text-right">
					<div><b>{{itemLabourTotalPrice(item) | decimal:2:2:true:data.quote.currency_id}}</b></div>
					<div *ngIf="itemHasLabourInvoiced(item)"><span class="badge badge-success">{{itemLabourInvoicedPercentage(item) | decimal:0}}% invoiced</span></div>
					<div class="text-small">Unit price: {{item.unit_labour_price | decimal:2:2:true:data.quote.currency_id}}</div>
					<div *ngIf="item.accessories_count" class="text-small">Accessories: {{item.accessories_labour_price | decimal:2:2:true:data.quote.currency_id}}</div>
				</td>

				<ng-container *ngIf="type ==='negative_labour_hour_margin'">
					<td class="text-right">{{item.unit_labour_hours | hours}}</td>
					<td class="text-right" *ngIf="item.total_recorded_hours">{{item.total_recorded_hours | hours}}</td>
				</ng-container>

				<ng-container *ngIf="type !=='negative_labour_hour_margin'">
					<td class="text-right">
						<div><b>{{(item.unit_cost * item.quantity * item.quantity_multiplier) | decimal:2:2:true:data.quote.currency_id}}</b></div>
						<div class="text-small">Unit cost: {{item.unit_cost | decimal:2:2:true:data.quote.currency_id}}</div>
						<div *ngIf="item.accessories_count" class="text-small">Accessories: {{item.accessories_cost | decimal:2:2:true:data.quote.currency_id}}</div>
					</td>

					<td class="text-right">
						<div><b>{{itemTotalPrice(item) | decimal:2:2:true:data.quote.currency_id}}</b></div>
						<div *ngIf="itemHasInvoiced(item)"><span class="badge badge-success">{{itemInvoicedPercentage(item) | decimal:0}}% invoiced</span></div>
						<div class="text-small">Unit price: {{item.unit_price | decimal:2:2:true:data.quote.currency_id}}</div>
						<div *ngIf="item.accessories_count" class="text-small">Accessories: {{item.accessories_price | decimal:2:2:true:data.quote.currency_id}}</div>
						<div *ngIf="item.subscription_count" class="text-small text-success"><i class="wq wq-subscription"></i> Subscription</div>
						<div *ngIf="item.has_factors" class="text-small text-warning"><i class="wq wq-refresh"></i> Factors</div>
					</td>

					<td class="text-center nowrap py-0">
						<ng-container *ngIf="!item.is_split">{{(item.quantity * item.quantity_multiplier) | decimal:0:4}} {{item.unit_name}}</ng-container>
						<ng-container *ngIf="item.is_split">{{(item.split_quantity * item.quantity_multiplier) | decimal:0:4}} of {{item.split_pack_quantity | decimal:0:4}} {{item.split_unit_name}}</ng-container>
					</td>
				</ng-container>

				<td class="text-center">
					<span *ngIf="item.parent_id" class="badge badge-secondary">Accessory</span>
					<span *ngIf="!item.parent_id" class="badge badge-primary">Main Item</span>
				</td>
			</tr>
		</tbody>
	</table>

	<button type="button" class="btn btn-secondary" (click)="viewLinesModal.close()" ui-modal-footer>Close</button>
</ui-modal>
