import { Component } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

@Component({
	selector: 'modal-select-accounts-assignment',
	templateUrl: './modal-select-accounts-assignment.component.html'
})
export class ModalSelectAccountsAssignmentComponent extends UIModalBaseComponent {

	search = '';
	data = null;

	constructor(
		public app: AppService,
		private api: ApiService,
	) {
		super();
	}

	open(mdata = null) {
		if (mdata) this.search = mdata;
		if (!this.data) {
			this.search = '';
			this.searchItems();
		}
		this.modal.open();
	}

	searchItems() {
		if (!this.data || !this.data.client_search) {
			if (this.data) this.data.list = null;
			this.api.settings.integrationAccountsSearchAssignments({ keywords: this.search }, data => {
				this.data = data;
			}, error => {
				this.app.notifications.showDanger(error.message);
				this.data = [];
			});
		}
	}

	selectItem(item) {
		this.modal.close(item);
	}

}
