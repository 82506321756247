import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIGridContainerComponent } from './ui-grid-container.component';
import { UIGridRowComponent } from './ui-grid-row.component';
import { UIGridColComponent } from './ui-grid-col.component';
import { UIGridBaseComponent } from './ui-grid-base.component';
import { UICardComponent } from './ui-card.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { UIGridHeaderComponent } from './ui-grid-header.component';
import { UIButtonBadgeComponent } from './ui-button-badge.component';
import { UiIconFeatureComponent } from './ui-icon-feature.component';
import { UIFormComponent } from './ui-form.component';
import { UIFormControlComponent } from './ui-form-control.component';
import { UIModalComponent } from './ui-modal.component';
import { UITreeComponent, UITreeNodeComponent } from './ui-tree.component';
import { UIWaitComponent } from './ui-wait.component';
import { UIEllipsisTooltipDirective } from './ui-ellipsis-tooltip.directive';
import { UIAlertComponent } from './ui-alert.component';
import { UIVideoComponent } from './ui-video.component';
import { UILabelComponent } from './ui-label.component';
import { UICheckboxDropdownComponent } from './ui-checkbox-dropdown.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UITabContentComponent, UITabsComponent } from './ui-tabs.component';
import { UIProgressTableComponent } from './ui-progress-table';
import { UIHoursProgressBarComponent } from './ui-hours-progress-bar';
import { UINoDataPlaceholderComponent } from './ui-no-data-placeholder.component';
import { SharedModule } from 'app/shared/shared.module';
import { SubtaskListComponent } from './ui-subtask-list/ui-subtask-list.component';
import { TaskCardComponent } from './ui-task-card/ui-task-card.component';
import { CalendarModule } from 'primeng/calendar';
import { UITaskProgressBarComponent } from './ui-task-progress-bar';
import { UIUserImageComponent } from './ui-user-image.component';
import { UIScheduleCalendarComponent } from './ui-schedule-calendar/ui-schedule-calendar.component';
import { LinkyModule } from 'ngx-linky';
import { UIResizableDirective } from './ui-resizable.directive';
import { UIResizeHandleComponent } from './ui-resize-handle.component';
import { MaterialListComponent } from './ui-material-list/ui-material-list.component';
import { UISiteVisitsProgressBarComponent } from './ui-site-visits-progress-bar';
import { UIUserStatusComponent } from './ui-user-status.component';
import { UITimeEntryApprovalStatusComponent } from './ui-timeentry-approval-status.component';
import { UITaskPriorityComponent } from './ui-task-priority';

@NgModule({
	declarations: [
		UIGridContainerComponent,
		UIGridRowComponent,
		UIGridColComponent,
		UIGridBaseComponent,
		UICardComponent,
		UIGridHeaderComponent,
		UIButtonBadgeComponent,
		UiIconFeatureComponent,
		UIFormComponent,
		UIFormControlComponent,
		UIModalComponent,
		UITreeComponent,
		UITreeNodeComponent,
		UIWaitComponent,
		UIEllipsisTooltipDirective,
		UIAlertComponent,
		UIVideoComponent,
		UILabelComponent,
		UICheckboxDropdownComponent,
		UITabsComponent,
		UITabContentComponent,
		UIProgressTableComponent,
		UIHoursProgressBarComponent,
		UINoDataPlaceholderComponent,
		SubtaskListComponent,
		TaskCardComponent,
		UITaskProgressBarComponent,
		UIUserImageComponent,
		UIScheduleCalendarComponent,
		UIResizableDirective,
		UIResizeHandleComponent,
		MaterialListComponent,
		UISiteVisitsProgressBarComponent,
		UIUserStatusComponent,
		UITimeEntryApprovalStatusComponent,
		UITaskPriorityComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		NgbTooltipModule,
		SharedModule,
		CalendarModule,
		LinkyModule
	],
	exports: [
		UIGridContainerComponent,
		UIGridRowComponent,
		UIGridColComponent,
		UIGridBaseComponent,
		UICardComponent,
		UIGridHeaderComponent,
		UIButtonBadgeComponent,
		UiIconFeatureComponent,
		UIFormComponent,
		UIFormControlComponent,
		UIModalComponent,
		UITreeComponent,
		UIWaitComponent,
		UIEllipsisTooltipDirective,
		UIAlertComponent,
		UIVideoComponent,
		UILabelComponent,
		UICheckboxDropdownComponent,
		UITabsComponent,
		UITabContentComponent,
		UIProgressTableComponent,
		UIHoursProgressBarComponent,
		UINoDataPlaceholderComponent,
		SubtaskListComponent,
		TaskCardComponent,
		UITaskProgressBarComponent,
		UIScheduleCalendarComponent,
		UIUserImageComponent,
		MaterialListComponent,
		UISiteVisitsProgressBarComponent,
		UIUserStatusComponent,
		UITimeEntryApprovalStatusComponent,
		UITaskPriorityComponent
	]
})
export class UIModule { }
