import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'hours'
})
export class HoursPipe implements PipeTransform {

	transform(decimalHours: number): string {
		if (decimalHours === null) return '';
		let hours = Math.floor(decimalHours);
		let minutes = Math.round((decimalHours - hours) * 60);

		if (minutes === 60) {
			minutes = 0;
			hours += 1;
		}

		let result = '';
		if (hours > 0) result += `${hours} ` + (hours === 1 ? 'hour' : 'hours');
		if (minutes > 0) result += ` ${minutes} ` + (minutes === 1 ? 'min' : 'mins');

		return result;
	}

}
