<form>
	<ui-modal [modalTitle]="title" size="lg">
		<button *ngFor="let tab of tabs" type="button" class="btn border-0 mr-2" [ngClass]="{ 'btn-outline-secondary': selectedTab !== tab.id, 'btn-primary': selectedTab === tab.id }" (click)="selectTab(tab.id)" ui-modal-header>{{tab.description}}</button>

		<ng-container *ngIf="data && selectedTab === 'details'">
			<ui-form labelWidth="12rem">
				<ui-form-control label="Subsystem Name">
					<div class="w-60">
						<input class="form-control" [(ngModel)]="data.record.description" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off" appDesktopFocus>
						<div *ngIf="quoteId && data.record.description !== data.original.description" class="text-secondary mt-1">
							<i class="wq wq-edit mr-1"></i> Original subsystem name: <b>{{data.original.description}}</b>.
							<br><button type="button" class="btn btn-sm btn-outline-secondary mt-1" (click)="data.record.description = data.original.description"><i class="wq wq-undo mr-2"></i>Restore</button>
						</div>
					</div>
				</ui-form-control>
				<ui-form-control *ngIf="!quoteId" label="System">
					<div class="d-flex w-60">
						<select class="custom-select" [(ngModel)]="data.record.category_group_id" [ngModelOptions]="{ standalone: true }">
							<option *ngFor="let item of data.groups" [ngValue]="item.id">{{item.description}}</option>
						</select>
						<div class="dropdown dropdown-light d-inline-block ml-1">
							<button type="button" class="btn btn-outline-success p-2 dropdown-toggle" data-toggle="dropdown"><i class="wq wq-add"></i></button>
							<div class="dropdown-menu">
								<button type="button" class="dropdown-item dropdown-item-success" (click)="addCategoryGroup()">Add System</button>
								<a [href]="'/' + app.org + '/system'" target="_blank" class="dropdown-item dropdown-item-success">Go to System Settings<i class="wq wq-open-new ml-2"></i></a>
							</div>
						</div>
					</div>
				</ui-form-control>
				<ui-form-control *ngIf="data.accounts_assignment" label="Accounts Assignment">
					<div class="d-flex w-60">
						<input type="text" class="form-control" [ngModel]="data.accounts_assignment.id ? data.accounts_assignment.name : 'Not selected'" [ngModelOptions]="{ standalone: true }" readonly>
						<button *ngIf="data.accounts_assignment.id" type="button" class="btn btn-outline-secondary p-2 ml-1" (click)="data.accounts_assignment.id = null"><i class="wq wq-close"></i></button>
						<button type="button" class="btn btn-outline-secondary p-2 ml-1" (click)="selectAssignment()"><i class="wq wq-search"></i></button>
					</div>
				</ui-form-control>

				<hr>

				<ui-form-control label="Summary">
					<div (click)="editContentModal.open(data.record.heading_content)" class="border border-lightgrey rounded selectable px-2 py-1 edit-content-container hover-parent">
						<button type="button" class="btn btn-primary edit-content-button hover-child"><i class="wq wq-edit mr-1"></i>Edit Content</button>
						<div *ngIf="!data.record.heading_content" class="text-secondary">Content</div>
						<div *ngIf="data.record.heading_content" [innerHtml]="data.record.heading_content | safeHtml" style="pointer-events: none;"></div>
					</div>

					<div class="text-info mt-1"><i class="wq wq-info mr-2"></i>This is shown in the subsystem heading section of your proposal.</div>
					<div *ngIf="quoteId && data.record.heading_content !== data.original.heading_content" class="text-secondary mt-1">
						<i class="wq wq-edit mr-1"></i> Content was customised.
						<br><button type="button" class="btn btn-sm btn-outline-secondary mt-1" (click)="data.record.heading_content = data.original.heading_content"><i class="wq wq-undo mr-2"></i>Restore</button>
					</div>
				</ui-form-control>

				<ui-form-control label="Header Image">
					<button *ngIf="!data.record.image_url" type="button" class="btn btn-medium" [disabled]="disabled" (click)="openMediaLibrary('image')"><i class="wq wq-edit mr-2"></i>Select Image</button>
					<div *ngIf="data.record.image_url" class="img-container d-flex hover-parent" [style.backgroundImage]="'url(\'' + data.record.image_url + '\')' | safeStyle">
						<div class="hover-child d-inline-block" style="margin: auto;">
							<button type="button" class="btn btn-medium mr-1" [disabled]="disabled" (click)="openMediaLibrary('image')"><i class="wq wq-edit mr-2"></i>Select Image</button>
							<button type="button" class="btn btn-danger" [disabled]="disabled" (click)="removeImage('image')"><i class="wq wq-delete mr-2"></i>Remove Image</button>
						</div>
					</div>
					<div *ngIf="quoteId && data.record.image_id !== data.original.image_id" class="text-secondary mt-1">
						<i class="wq wq-edit mr-1"></i> Content was customised.
						<br><button type="button" class="btn btn-sm btn-outline-secondary mt-1" (click)="restoreImage('image')"><i class="wq wq-undo mr-2"></i>Restore</button>
					</div>
				</ui-form-control>

				<ng-container *ngIf="app.orgInfo.allow_extra_headers">
					<ui-form-control label="Header Icon">
						<button *ngIf="!data.record.heading_icon_url" type="button" class="btn btn-medium" [disabled]="disabled" (click)="openMediaLibrary('heading_icon')"><i class="wq wq-edit mr-2"></i>Select Image</button>
						<div *ngIf="data.record.heading_icon_url" class="img-container d-flex hover-parent bg-silver" style="background-size: contain;" [style.backgroundImage]="'url(\'' + data.record.heading_icon_url + '\')' | safeStyle">
							<div class="hover-child d-inline-block" style="margin: auto;">
								<button type="button" class="btn btn-medium mr-1" [disabled]="disabled" (click)="openMediaLibrary('heading_icon')"><i class="wq wq-edit mr-2"></i>Select Image</button>
								<button type="button" class="btn btn-danger" [disabled]="disabled" (click)="removeImage('heading_icon')"><i class="wq wq-delete mr-2"></i>Remove Image</button>
							</div>
						</div>
						<div *ngIf="quoteId && data.record.heading_icon_id !== data.original.heading_icon_id" class="text-secondary mt-1">
							<i class="wq wq-edit mr-1"></i> Content was customised.
							<br><button type="button" class="btn btn-sm btn-outline-secondary mt-1" (click)="restoreImage('heading_icon')"><i class="wq wq-undo mr-2"></i>Restore</button>
						</div>
					</ui-form-control>
					<ui-form-control label="Header URL">
						<input class="form-control" [(ngModel)]="data.record.heading_url" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
						<div *ngIf="quoteId && data.record.heading_url !== data.original.heading_url" class="text-secondary mt-1">
							<i class="wq wq-edit mr-1"></i> Original URL: <b>{{data.original.heading_url}}</b>.
							<br><button type="button" class="btn btn-sm btn-outline-secondary mt-1" (click)="data.record.heading_url = data.original.heading_url"><i class="wq wq-undo mr-2"></i>Restore</button>
						</div>
					</ui-form-control>
					<ui-form-control label="Header Template">
						<select class="custom-select" [(ngModel)]="data.record.heading_template_id" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
							<option [ngValue]="null">Default</option>
							<option *ngFor="let t of headerList" [ngValue]="t.id">{{t.name}}</option>
						</select>
						<div *ngIf="quoteId && data.record.heading_template_id !== data.original.heading_template_id" class="text-secondary mt-1">
							<i class="wq wq-edit mr-1"></i> Template was customised.
							<br><button type="button" class="btn btn-sm btn-outline-secondary mt-1" (click)="data.record.heading_template_id = data.original.heading_template_id"><i class="wq wq-undo mr-2"></i>Restore</button>
						</div>
					</ui-form-control>
					<ng-container *ngIf="findHeader(data.record.heading_template_id); let header">
						<ng-container *ngIf="header.customisable?.length">
							<ui-form-control *ngFor="let custom of header.customisable" [label]="custom.description">
								<input *ngIf="custom.type === 'text'" type="text" class="form-control" [value]="getCustomisedValue(custom.id)" (input)="setCustomisedValue(custom.id, $event.target.value)" autocomplete="chrome-off">

								<textarea *ngIf="custom.type === 'textarea'" rows="3" class="form-control" [value]="getCustomisedValue(custom.id)" (input)="setCustomisedValue(custom.id, $event.target.value)" autocomplete="chrome-off"></textarea>

								<div *ngIf="custom.type === 'image'" class="d-flex align-items-center">
									<div *ngIf="getCustomisedUrl(custom.id, custom.value)" class="product-image" [style.backgroundImage]="'url(\'' + getCustomisedUrl(custom.id, custom.value) + '\')' | safeStyle"></div>
									<div class="ml-3">
										<button type="button" class="btn btn-sm btn-outline-secondary border-0 px-1" (click)="openMediaLibraryCustomisable(custom.id)"><i class="wq wq-edit mr-2"></i>Edit Image</button>
									</div>
									<div class="ml-3">
										<button *ngIf="isCustomised(custom.id)" type="button" class="btn btn-sm btn-outline-danger border-0 px-1" (click)="removeCustomImage(custom.id)"><i class="wq wq-undo mr-2"></i>Default Image</button>
									</div>
								</div>
							</ui-form-control>
						</ng-container>
					</ng-container>
				</ng-container>
			</ui-form>
			<div *ngIf="quoteId" class="alert mb-0 mt-4" [ngClass]="{ 'alert-warning': !data.save_to_config, 'alert-success': data.save_to_config }">
				<div class="d-flex align-items-center">
					<div *ngIf="!data.save_to_config" class="flex-weight-1">
						<i class="wq wq-warning mr-1"></i> Changes made to the name or summary will be saved to <b>this quote only</b>.
					</div>
					<div *ngIf="data.save_to_config" class="flex-weight-1">
						<i class="wq wq-check mr-1"></i> Changes will be saved to global configuration.
					</div>
					<div>
						<label class="custom-control custom-checkbox mb-0">
							<input type="checkbox" class="custom-control-input" [(ngModel)]="data.save_to_config" [ngModelOptions]="{ standalone: true }">
							<span class="custom-control-label">Save to configuration</span>
						</label>
					</div>
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="data && selectedTab === 'toolkit'">
			<div class="d-flex align-items-center mb-3">
				<h3 class="flex-weight-1">Toolkit items</h3>
				<span><button type="button" class="btn btn-success ml-3" (click)="addProduct()"><i class="wq wq-add mr-2"></i>Add products to toolkit</button></span>
			</div>
			<div class="scrollable cdk-drop-list" style="height: 400px;" (cdkDropListDropped)="toolkitDrop($event)" cdkDropList>
				<table class="table table-hover vam">
					<tbody>
						<tr *ngFor="let item of data.toolkit" class="cdk-drag" [ngClass]="{ 'table-success': data.toolkit_add.indexOf(item.id) !== -1 }" cdkDrag>
							<td class="pr-0 shrink text-silver draggable"><i class="wq wq-drag-indicator"></i></td>
							<td class="shrink pr-0 draggable">
								<div *ngIf="item.image_url" class="product-image mr-3" [style.backgroundImage]="'url(\'' + item.image_url + '\')' | safeStyle"></div>
							</td>
							<td class="pl-0 draggable">
								<div *ngIf="item.short_description" class="text-bold">{{item.short_description}}</div>
								<div *ngIf="item.manufacturer_name || item.model" class="text-secondary">{{item.manufacturer_name}} {{item.manufacturer_name && item.model ? '/' : ''}} {{item.model}}</div>
								<div *ngIf="item.sku || item.manufacturer_sku" class="text-secondary text-tiny">{{item.sku || item.manufacturer_sku}}</div>
								<div *ngIf="item.discontinued" class="text-danger text-bold"><i class="wq wq-error"></i> Product discontinued</div>
							</td>
							<td class="shrink nowrap px-3 draggable" [ngClass]="{ 'text-danger': !item.catalogue_linked }">
								<ng-container *ngIf="item.catalogue_id">
									<span class="cat-image border bg-white border-silver rounded-circle mr-2 shadow-sm" [style.backgroundImage]="'url(\'' + item.catalogue_image_url + '\')' | safeStyle"></span>
									{{item.catalogue_name}}
									<i *ngIf="!item.catalogue_linked" class="wq wq-error ml-1" ngbTooltip="Catalogue is not linked to your account."></i>
								</ng-container>
							</td>
							<td class="shrink nowrap"><button *ngIf="item.catalogue_linked" type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="editProduct(item)"><i class="wq wq-edit"></i></button></td>
							<td class="shrink nowrap pl-0"><button type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="removeItem(item)"><i class="wq wq-close"></i></button></td>
						</tr>
						<tr *ngIf="data.toolkit.length === 0">
							<td class="text-secondary">No products in the toolkit.</td>
						</tr>
					</tbody>
				</table>
			</div>
		</ng-container>

		<button *ngIf="canDelete" type="button" class="btn btn-danger mr-2" (click)="deleteCategory()" [disabled]="disabled" ui-modal-footer>Delete</button>
		<button *ngIf="canArchive" type="button" class="btn btn-danger mr-2" (click)="archiveCategory()" [disabled]="disabled" ui-modal-footer>Delete</button>
		<button *ngIf="canUnarchive" type="button" class="btn btn-danger mr-2" (click)="unarchiveCategory()" [disabled]="disabled" ui-modal-footer>Restore</button>
		<div class="flex-weight-1" ui-modal-footer></div>
		<button type="button" class="btn btn-medium mr-2" (click)="modal.close()" [disabled]="disabled" ui-modal-footer>Cancel</button>
		<button type="submit" class="btn btn-primary" (click)="save()" [disabled]="disabled" ui-modal-footer>Save</button>
	</ui-modal>
</form>

<modal-select-product (close)="productSelectModalClosed($event)"></modal-select-product>
<modal-edit-content (close)="editContentModalClosed($event)"></modal-edit-content>
<modal-edit-category-group (close)="editCategoryGroupModalClosed($event)"></modal-edit-category-group>
<modal-edit-product (close)="editProductModalClosed($event)"></modal-edit-product>
<modal-select-accounts-assignment (close)="selectAssignmentModalClosed($event)"></modal-select-accounts-assignment>
