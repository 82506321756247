import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'mySQLDateToISO'
})
export class MySQLDateToISOPipe implements PipeTransform {

	static stringToDate(str: string) {
		if (!str) return null;

		if (str.includes(':') && !str.includes(' ')) {
			// Time only
			const [hours, minutes, seconds] = str.split(':').map(Number);
			const date = new Date();
			date.setHours(hours, minutes, seconds, 0);
			return date;
		} else if(!str.includes(' ') && !str.includes('T')) {
			// Date only
			str += ' 00:00:00';
			return new Date(MySQLDateToISOPipe.transform(str));
		} else {
			// Date and time
			return new Date(MySQLDateToISOPipe.transform(str));
		}
	}

	static dateToString(d: Date, isTime: boolean = false) {
		if (!d || !(d instanceof Date)) return null;
		d = new Date(d);

		// Helper function for padding
		const pad = (num: number) => (num < 10 ? '0' : '') + num;

		// If it's a time, return in HH:MM:SS format
		if (isTime) {
			return [
				pad(d.getHours()),
				pad(d.getMinutes()),
				pad(d.getSeconds())
			].join(':');
		}

		// Otherwise return in DATETIME format (YYYY-MM-DD HH:MM:SS)
		d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
		return d.toISOString().split('.')[0].replace('T', ' ');
	}

	static transform(value: any) {
		if (!value) return null;

		if (value.includes(':') && !value.includes(' ')) {
			return value;
		}
		return ('' + value).replace(' ', 'T');
	}

	transform(value: any): any {
		return MySQLDateToISOPipe.transform(value);
	}
}
