import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { MySQLDateToISOPipe } from 'app/shared/mysql-date-to-iso.pipe';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';
import { UIModalComponent } from 'app/ui/ui-modal.component';

declare const Mangler: any;

@Component({
	selector: 'modal-quote-mark-as-sent',
	templateUrl: './modal-quote-mark-as-sent.component.html'
})
export class ModalQuoteMarkAsSentComponent extends UIModalBaseComponent {

	@ViewChild('viewLines') viewLinesModal: UIModalComponent;

	disabled = false;
	issues = null;
	issueTitle = '';
	data = null;
	quota;

	disableOldProposals = false;
	redirectOldProposals = false;
	confirmed = {};

	record = {
		id: 0,
		quote_date: null,
		expiry_date: null,
		accepted_date: null
	};

	lineIssues = ['zero_cost', 'zero_price', 'zero_margin', 'negative_margin', 'special_order_items', 'negative_labour_hour_margin'];
	type;
	constructor(
		public app: AppService,
		private api: ApiService,
		private router: Router
	) {
		super();
	}

	open(mdata: any) {
		this.disableOldProposals = !!this.app.orgInfo?.disable_old_proposals;
		this.redirectOldProposals = !!this.app.orgInfo?.redirect_old_proposals;

		this.api.quote.getDefaultExpiryDate(mdata.quoteId, data => {
			this.record = data;
			this.record.quote_date = MySQLDateToISOPipe.stringToDate(this.record.quote_date);
			this.record.expiry_date = MySQLDateToISOPipe.stringToDate(this.record.expiry_date);

			this.api.quote.issues(mdata.quoteId, issueData => {
				this.issues = issueData;
				Mangler.each(this.issues.warnings, (k, v) => {
					if (this.lineIssues.includes(k)) {
						this.confirmed[k] = !v.length;
					} else {
						this.confirmed[k] = !v;
					}
				});

				if (this.app.routeData.module === 'quote') this.modal.open();
				else {
					this.api.quote.downloadQuota(this.record.id, quota => {
						this.quota = quota;
						this.record.accepted_date = MySQLDateToISOPipe.stringToDate(quota.today);
						this.modal.open();
					}, error => {
						if (error.code === 1) {
							// Cannot accept quote, pop message asking them to upgrade their account.
							const buttons = ['Close'];
							if (this.app.orgInfo.role.manage_billing) buttons.push('*Go to Billing Settings');

							this.app.dialog.pop(this.app.routeData.module === 'sales-order' ? 'Finalise Sales Order' : 'Accept Quote', error.message, buttons, button => {
								if (button === 1) {
									this.router.navigate(['/' + this.app.orgInfo.alias, 'settings', 'billing']);
								}
							});
						} else {
							this.app.notifications.showDanger(error.message);
						}
					});
				}
			}, error => {
				this.app.notifications.showDanger(error.message);
			});
		}, error => {
			this.app.notifications.showDanger(error.message);
		});
	}

	markAsSentButton() {
		this.disabled = true;

		const data = Mangler.clone(this.record);
		data.quote_date = MySQLDateToISOPipe.dateToString(data.quote_date);
		data.expiry_date = MySQLDateToISOPipe.dateToString(data.expiry_date);
		data.disable_old_proposals = this.disableOldProposals ? 1 : 0;
		data.redirect_old_proposals = this.redirectOldProposals ? 1 : 0;

		this.api.quote.markAsSent(data, () => {
			this.disabled = false;
			this.app.quotePriceChange.emit();
			this.modal.close(true);
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

	markAsCompletedButton() {
		this.disabled = true;

		const data = Mangler.clone(this.record);
		data.accepted_date = MySQLDateToISOPipe.dateToString(data.accepted_date);

		this.api.quote.markSalesOrderAsCompleted(data, () => {
			this.disabled = false;
			this.app.quotePriceChange.emit();
			this.app.quoteStageChange.emit('complete');
			this.modal.close(true);
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

	issueDetails(type) {
		this.type = type;
		const list = this.issues.warnings[type];
		if (!list?.length) return;

		switch (type) {
			case 'zero_cost': this.issueTitle = 'Items with zero cost'; break;
			case 'zero_price': this.issueTitle = 'Items with zero price'; break;
			case 'zero_margin': this.issueTitle = 'Items with zero margin'; break;
			case 'negative_margin': this.issueTitle = 'Items with negative margin'; break;
			case 'special_order_items': this.issueTitle = 'Special order items'; break;
			case 'negative_labour_hour_margin': this.issueTitle = 'Labour hours mismatch'; break;
			default: this.issueTitle = 'Items'; break;
		};

		this.data = null;
		this.api.quote.lines({ id: this.record.id, line_list: list }, data => {
			this.data = data;

			// Put areas into floors
			const floorIndex = {};
			this.data.floors = [];
			this.data.structure.forEach(s => {
				if (s.type === 'floor') {
					s.areas = [];
					this.data.floors.push(s);
					floorIndex[s.id] = s;
				}
			});
			this.data.structure.forEach(s => {
				if (s.type === 'area') {
					const floor = floorIndex[s.parent_id];
					if (floor) floor.areas.push(s);
				}
			});

			// Add categories to category groups
			this.data.category_groups.forEach(cg => {
				cg.total = 0;
				cg.categories = Mangler.find(this.data.categories, { category_group_id: cg.id });
			});

			this.viewLinesModal.open();
		}, error => {
			this.app.notifications.showDanger(error.message);
		});
	}

	issueList() {
		const list = [];
		Mangler.each(this.issues.warnings, (k, v) => {
			if (this.lineIssues.includes(k) && v.length) list.push(k);
		});
		return list;
	}

	isConfirmed() {
		if (!this.issues) return;
		let confirmed = true;
		Mangler.each(this.confirmed, (_k, v) => { if (!v) confirmed = false; });
		return confirmed;
	}

	isDiscontinued(id) {
		return id && this.data && this.data.discontinued.indexOf(id) !== -1;
	}

	itemHasInvoiced(item) { return item.amount_invoiced > 0; }
	itemInvoicedAmount(item) { return item.amount_invoiced; }
	itemTotalPrice(item) { return item.unit_price * item.quantity * item.quantity_multiplier; }

	itemInvoicedPercentage(item) {
		let percentage = 0;
		const invoiced = this.itemInvoicedAmount(item);
		const total = this.itemTotalPrice(item);
		if (total > 0) percentage = (invoiced / total) * 100;
		return percentage;
	}

	itemHasLabourInvoiced(item) { return item.labour_amount_invoiced > 0; }
	itemLabourInvoicedAmount(item) { return item.labour_amount_invoiced; }
	itemLabourTotalPrice(item) { return item.unit_labour_price * item.quantity * item.quantity_multiplier; }

	itemLabourInvoicedPercentage(item) {
		let percentage = 0;
		const invoiced = this.itemLabourInvoicedAmount(item);
		const total = this.itemLabourTotalPrice(item);
		if (total > 0) percentage = (invoiced / total) * 100;
		return percentage;
	}

}
