<form>
	<ui-modal [modalTitle]="data?.title" size="lg" [scrollable]="true">

		<div *ngIf="data && modal.isOpen" class="input-group mb-2" ui-modal-sticky-header>
			<span class="input-group-prepend"><span class="input-group-text"><i class="wq wq-search"></i></span></span>
			<input type="search" class="form-control" placeholder="Search" [(ngModel)]="search" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off" spellcheck="false" appDesktopFocus>
			<span class="input-group-append">
				<button type="submit" class="btn btn-primary" (click)="searchItems()"><i class="wq wq-search mr-2"></i>Search Items</button>
			</span>
		</div>

		<div class="mb-2" style="min-height: 30rem;">
			<ui-wait *ngIf="!data?.list"></ui-wait>

			<ng-container *ngIf="data">
				<table *ngIf="data.list?.length" class="table table-hover vam">
					<thead>
						<tr>
							<th>{{data.id_column}}</th>
							<th>{{data.name_column}}</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of data.list | keywords:(data.client_search ? search : ''):['id', 'name']" class="selectable" (click)="selectItem(item)">
							<td>{{item.id}}</td>
							<td class="text-bold">{{item.name}}</td>
						</tr>
					</tbody>
				</table>
				<p *ngIf="data.list && !data.list.length" class="text-secondary mt-3">No items found.</p>
			</ng-container>
		</div>

		<button type="button" class="btn btn-secondary" (click)="modal.close()" ui-modal-footer>Cancel</button>

	</ui-modal>
</form>
